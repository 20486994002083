import React, {useEffect, useState, useRef} from 'react'
import {deepCopy, isArray, isObject, formatNumberWithCommas, scrollToTop, removeCommas} from '@/utils'
import api from '@/api'
import ReactEcharts from 'echarts-for-react'
import {decode} from 'js-base64'
import {useSearchParams} from "react-router-dom";
import { Button, message, Watermark} from 'antd'
import html2pdf from "html2pdf.js";
import {VerticalAlignBottomOutlined} from '@ant-design/icons'
import s from './index.module.scss'

const OldDetail = (props) => {
  const data = props.data
  const [spinning, setSpinning] = useState(false)
  const [searchParams] = useSearchParams(); // 获取查询参数
  const watermark = [JSON.parse(localStorage.getItem('pca_user')).companyName + '--' + JSON.parse(localStorage.getItem('pca_user')).name, JSON.parse(localStorage.getItem('pca_user')).phoneNumber]
  const industries = searchParams.get('industries')
  const companyId = searchParams.get('id')
  const getEchartsRef = useRef(null);
  const basicData = props.data || null
  const [columns, setColumns] = useState([])
  const [tableData, setTableData] = useState([])
  const [analyticalTextData, setAnalyticalTextData] = useState([])
  const [impact, setImpact] = useState([])
  const [listData, setListData] = useState([])
  const [echartsData, setEchartsData] = useState([])
  const [imageUrl, setImageUrl] = useState('')
  const [extra, setExtra] = useState([])

  useEffect(() => {
    scrollToTop()

  }, [])

  const getEcharts = async (copyData) => {
    let result = await api.getQualityDistribution({sector: copyData?.sector})
    if (result?.code == 200) {
      let data = result.data
      data.splice(0, 1)
      let ccc = data.reduce((acc, curr) => acc + (curr.quality.indexOf('c') >= 0 ? Number(curr.qualityNum) : 0), 0);
      let index = data.findIndex(item => {
        return item.quality === 'ccc'
      })
      data[index].quality = 'ccc及以下'
      data[index].qualityNum = ccc
      // if(quality.indexOf('c')>=0)
      setEchartsData(data)
      // setTimeout(()=>{
      // 	handleEchartsToImage()
      // },1000)
    }
  }
  const getMaxObject = (arr, key) => {
    if (arr.length === 0) return null;

    let maxObject = arr[0];
    for (let i = 1; i < arr.length; i++) {
      if (arr[i][key] > maxObject[key]) {
        maxObject = arr[i];
      }
    }
    return maxObject;
  }
  const getExtra = async (copyData) => {
    let newData = copyData?.doc.industryQuantile
    let theCompany = copyData?.doc.entityFinancialData.filter((d) => {
      return d.企业名称 === copyData?.doc?.entityName
    })

    let maxCompany = getMaxObject(theCompany, '年份')
    let titleData = {
      name: 'doc',
      the: '本公司' + maxCompany.年份,
      two: '行业25%分位数',
      five: '行业50%分位数',
      seven: '行业75%分位数'
    }
    let newResult = []
    maxCompany?.financialIndicator.map(item => {
      let obj = {
        name: item.indicatorName,
        the: item.indicatorValue
      }
      newResult.push(obj)
    })

    for (let item in newData) {
      let demo = newData[item]
      let findObj = newResult.find(t => {
        return t.name === item
      })
      if (findObj) {
        findObj.two = demo['25']
        findObj.five = demo['50']
        findObj.seven = demo['75']
      }
    }
    newResult.unshift(titleData)
    setExtra(newResult)
  }
  const getChartOptions = () => {
    let options = {
      title: {
        text: '约 4500 家样本企业主体信用质量分布',
        left: "center",
        top: 0
      },
      tooltip: {},
      legend: {
        show: true
      },
      grid: {
        left: "50px", right: "50px", bottom: '65px'
      },
      xAxis: {
        data: ["aaa1", "aaa2", "aaa3", "aaa4", "aaa5", "aa+1", "aa+2", "aa", "aa-", "a+", "a", "a-", "bbb+", "bbb", "bbb-", "bb+", "bb", "bb-", "b+", "b", "b-", "ccc及以下"],
        axisLabel: {
          rotate: 45 // 旋转角度
        }
      },
      yAxis: {
        type: "value",
        name: '企业数量',
        nameLocation: 'end',
        nameGap: 30,
        // color: new echarts.graphic.Image({
        //  style: {
        //   image: '@/assets/images/customer.png', // 图片的URL
        //   repeat: 'repeat' // 如果要平铺图片，设置为 'repeat-x', 'repeat-y' 或 'no-repeat'
        //  }
        // })
      },
      series: [
        {
          type: "bar",
          data: filterData(data),
        },
      ],
    }
    return options;
  }
  const filterData = (data) => {
    return echartsData.map((item, index) => {
      return {
        key: index,
        value: item.qualityNum,
        itemStyle: {
          color: item.quality === 'ccc及以下' ? '#A9A9A9' : getColor(index),
        }
      }
    });
  }
  const getColor = (number = 0) => {
    let num = number + 1;
    switch (true) {
      case num >= 1 && num <= 5:
        return '#0a6b5b';
      case num >= 6 && num <= 9:
        return '#06927c';
      case num >= 7 && num <= 9:
        return '#08c3a5';
      case num >= 10 && num <= 12:
        return '#08c3a5';
      case num >= 13 && num <= 15:
        return '#ffac17';
      case num >= 16 && num <= 18:
        return '#d6002a';
      default:
        return '#c40067'
    }
  }
  const handleEchartsToImage = () => {
    if (getEchartsRef.current) {
      // 获取 Echarts 实例
      const echartsInstance = getEchartsRef.current.getEchartsInstance();
      // 使用 getDataURL 方法生成图片的 base64 URL
      const imageUrl = echartsInstance.getDataURL({
        type: 'png',
        pixelRatio: 2,
        backgroundColor: '#fff',
      });
      setImageUrl(imageUrl)
    }
  };
  const replaceNewlinesWithTwo = (text) => {
    let configContent = text.replace(/^[\r\n]+/, '').replace(/\r/g, '\n')
    return configContent.replace(/\n+/g, '\n\n')
  }
  useEffect(() => {
    const copyData = deepCopy(props.data)
    if (copyData) {
      getEcharts(deepCopy(props.data))
      getExtra(deepCopy(props.data))
      let config = getCompanyInfoData('left', copyData?.sector == '金融机构')
      setListData(config)
      const configImpact = []
      for (let item in copyData?.doc.adjustmentItem) {
        configImpact.push({name: item, val: copyData?.doc.adjustmentItem[item]})
      }
      setImpact(configImpact)
      const analyticalText = copyData?.doc.analyticalText
      let arr = []
      if (analyticalText && analyticalText.length > 0) {
        analyticalText.map(item => {
          if (item.contentInBase64) {
            let _str = decode(item.contentInBase64)
            _str = _str.replace(/\r\n/g, '\n')
            if (_str.slice(0, 1) == "\n") {
              _str = _str.replace(/\n/, '')
            }
            arr.push({title: item.title, contentBase64: replaceNewlinesWithTwo(_str)})
          }
        })
      }
      setAnalyticalTextData(arr)
      const selfData = copyData?.doc?.entityFinancialData
      const diffData = copyData?.doc?.peerEntityFinancialData || copyData?.doc?.peerFinancialData || []
      let yearStr = ''
      if (isArray(selfData) && selfData?.length > 0) {
        yearStr = [...selfData, ...diffData].reduce((acc, cur) => {
          if (!acc[cur.entityId]) {
            acc[cur.entityId] = cur['年份']
            return acc
          } else {
            acc[cur.entityId] = acc[cur.entityId] + '/' + cur['年份']
            return acc
          }
        }, {})
        const newData = [...selfData, ...diffData].reduce((acc, cur) => {
          if (!acc[cur.entityId]) {
            let obj = {}
            obj[cur.entityId] = cur
            obj[cur.entityId]['年份'] = yearStr[cur.entityId]
            return Object.assign({}, acc, obj)
          } else {
            acc[cur.entityId].financialIndicator = acc[cur.entityId].financialIndicator.map((item, index) => {
              item.indicatorValue = (Number(removeCommas(item.indicatorValue)) + Number(removeCommas(cur.financialIndicator[index]?.indicatorValue))) / 2
              return item
            })
            return acc
          }
        }, {})
        getKeyIndicatorsColumns(deepCopy(newData), 'normal', copyData?.doc?.entityId)
        getKeyIndicatorsTableData(deepCopy(newData), 'normal')
      } else if (isObject(selfData) && selfData.financialIndicator.length > 0) {
        yearStr = [...[selfData], ...diffData].reduce((acc, cur, index) => {
          acc[index] = cur['财务指标年度']
          return acc
        }, {})
        const result = [selfData];
        const newData = [...result, ...diffData].reduce((acc, cur, index) => {
          // 没有 entityId 的情况下
          let obj = {}
          obj[cur['中文全称']] = cur
          obj[cur['中文全称']]['财务指标年度'] = yearStr[index]
          return Object.assign({}, acc, obj)
        }, {})
        getKeyIndicatorsColumns(deepCopy(newData), 'special', copyData?.doc?.entityName)
        getKeyIndicatorsTableData(deepCopy(newData), 'special')
      }
    }
  }, [props.data])
  const getCompanyInfoData = (type, isFIType) => {
    const FI_LEFT = [
      {title: '评级基准', variable: 'anchor'},
      {title: '业务状况', variable: 'businessProfile', sub: 1},
      {title: '资本与盈利性', variable: 'capitalProfitability', sub: 1},
      {title: '风险状况', variable: 'riskProfile', sub: 1},
      {title: '融资与流动性', variable: 'financingLiquidity', sub: 1},
      {title: '补充调整', variable: 'additionalAdjustment', sub: 1},
      {title: '个体信用质量', variable: 'sacp'},
      // {title:'外部支持重要性', variable:'externalSupportImportance'},
      {title: '外部影响', variable: 'finalNotching'},
      {title: '未经调整集团信用质量（如适用）', variable: 'unadjustedSacp', sub: 1},
      {title: '控股公司的特有风险因素（如适用）', variable: 'fhcRisk', sub: 1},
      {title: '主体信用质量', variable: 'quality'}
    ], CORP_LEFT = [
      {title: '行业划分', variable: 'industryName'},
      {title: '业务/经营状况', variable: 'brp'},

      {title: '行业风险', variable: 'industryRisk', sub: 1},
      {title: '竞争地位', variable: 'competitivePosition', sub: 1},
      {title: '财务风险', variable: 'frp'},
      {title: '评级基准', variable: 'anchor'},
      {title: '调整项', variable: 'adjustmentSum'},
      {title: '个体信用质量', variable: 'sacp'},
      {title: '外部影响', variable: 'externalImpact'},
      {title: '主体信用质量', variable: 'quality'},
    ]
    const renderArr = []
    if (type == 'left') {
      (isFIType ? FI_LEFT : CORP_LEFT).map(item => {
        if (data?.doc?.[item.variable]) renderArr.push({
          top: data.doc[item.variable],
          center: item.title,
          sub: item.sub ? item.sub : 0
        })
      })
    } else {
      if (data?.doc?.adjustmentItem) {
        Object.keys(data.doc.adjustmentItem).map(key => renderArr.push({
          top: data.doc.adjustmentItem[key],
          center: key
        }))
      }
    }
    return renderArr
  }
  const getKeyIndicatorsTableData1 = (data) => {
    if (data?.doc?.entityFinancialData) {
      if (isArray(data?.doc?.entityFinancialData) && data.doc.entityFinancialData.length > 0) {
        const result = [];
        const indicators = new Set(); // 用于去重指标名称
        // 遍历数据，收集所有独特的指标名称
        data.doc.entityFinancialData.forEach(item => {
          item.financialIndicator.forEach(indicator => {
            indicators.add(indicator.indicatorName);
          });
        });
        // 转换为所需格式
        indicators.forEach(indicatorName => {
          const entry = {name: indicatorName};
          data.doc.entityFinancialData.forEach(item => {
            const value = item.financialIndicator.find(i => i.indicatorName === indicatorName)?.indicatorValue;
            if (value) {
              entry[item.年份] = value;
            }
          });
          result.push(entry);
        });
        return result;
      } else if (isObject(data?.doc?.entityFinancialData) && data.doc.entityFinancialData.financialIndicator.length > 0) {
        const result = [];
        data.doc.entityFinancialData.financialIndicator.map(item => {
          if (item.indicatorValue) {
            if (item.indicatorName != 'icrSub') {
              result.push({
                name: item.indicatorName,
                [data.doc.entityFinancialData['财务指标年度'] || '-']: item.indicatorValue
              })
            }
          }
        })
        return result
      }
    }
  }
  // 关键指标数据处理
  const getKeyIndicatorscolumns1 = (data) => {
    let columns = [
      {
        title: ' ',
        dataIndex: 'name',
        key: 'name',
        width: 200
      },
    ]
    if (data?.doc?.entityFinancialData) {
      if (isArray(data?.doc?.entityFinancialData) && data.doc.entityFinancialData.length > 0) {
        let carr = []
        data.doc.entityFinancialData.map(item => {
          carr.push({
            title: item['年份'],
            dataIndex: item['年份'],
            key: item['年份'],
            width: 200
          })
        })
        let rearr = carr.reverse()
        columns = [...columns, ...rearr]
      } else if (isObject(data?.doc?.entityFinancialData) && data.doc.entityFinancialData.financialIndicator.length > 0) {
        columns.push({
          title: data.doc.entityFinancialData['财务指标年度'] || '-',
          dataIndex: data.doc.entityFinancialData['财务指标年度'] || '-',
          key: data.doc.entityFinancialData['财务指标年度'] || '-',
          width: 200
        })
      }
    }
    return columns
  }
  const getKeyIndicatorsColumns = (data = [], type, ownID) => {
    const newData = Object.values(data)
    let columns = []
    if (type === 'normal') {
      columns = newData.map((item) => {
        return {
          title: item['年份'],
          dataIndex: item.entityId,
          key: item.entityId,
        }
      })
    } else if (type === 'special') {
      columns = newData.map((item) => {
        return {
          title: item['财务指标年度'],
          dataIndex: item['中文全称'],
          key: item['中文全称'],
        }
      })
    }
    columns.unshift({
      title: props?.data?.sector === '工商企业' ? '同业比较（两年平均）' : '同业比较',
      dataIndex: 'comparative',
      key: 'comparative'
    })
    const newColumns = moveColumnToFirst(ownID, columns)
    setColumns(newColumns)
  }
  const getKeyIndicatorsTableData = (data = [], type) => {
    const newData = Object.values(data)
    if (type === 'normal') {
      setTableData(transformData(newData))
    } else if (type === 'special') {
      setTableData(transformData_special(newData))
    }
  }

  // 转换函数
  const transformData = (input) => {
    const companyNames = {};
    const financialIndicators = [];

    input.forEach(item => {
      // 收集企业名称
      companyNames[item.entityId] = item.企业名称;

      // 收集财务指标
      item.financialIndicator.forEach((indicator, index) => {
        const indicatorIndex = financialIndicators.findIndex(fin => fin.comparative === indicator.indicatorName);
        if (indicatorIndex === -1) {
          financialIndicators.push({
            [item.entityId]: formatNumberWithCommas(indicator.indicatorValue),
            key: index + 1,
            comparative: indicator.indicatorName,
          });
        } else {
          financialIndicators[indicatorIndex][item.entityId] = formatNumberWithCommas(indicator.indicatorValue);
        }
      });
    });

    // 添加第一行企业名称
    const firstRow = {
      key: 0,
      comparative: data?.sector === '工商企业' ? '主体名称' : ' ',
      ...companyNames
    };

    financialIndicators.unshift(firstRow);
    return financialIndicators;
  };

  const transformData_special = (cData) => {
    // 初始化结果数组
    const result = [];

    // 添加银行名称行
    const bankNamesRow = {
      key: 0,
      comparative: " ",
    };

    // 添加银行数据
    cData.forEach(bank => {
      bankNamesRow[bank['中文全称']] = bank['中文简称'];
    });
    if (data?.sector === '工商企业') bankNamesRow.comparative = '主体名称'
    result.push(bankNamesRow);

    // 处理各个财务指标
    const indicators = cData[0].financialIndicator.map(ind => ({
      name: ind.indicatorName,
      values: {}
    }));

    cData.forEach(bank => {
      bank.financialIndicator.forEach(ind => {
        const indicatorEntry = indicators.find(i => i.name === ind.indicatorName);
        if (indicatorEntry) {
          indicatorEntry.values[bank['中文全称']] = ind.indicatorValue;
        }
      });
    });

    // 构建结果
    indicators.forEach((indicator, index) => {
      const indicatorRow = {
        key: index + 1,
        comparative: indicator.name,
      };
      Object.entries(indicator.values).forEach(([bankName, value]) => {
        indicatorRow[bankName] = value;
      });
      result.push(indicatorRow);
    });
    return result;
  }
  const moveColumnToFirst = (columnDataIndex, columns) => {
    const newColumns = columns.slice();
    const targetColumn = newColumns.find((col) => col.dataIndex == columnDataIndex);
    if (targetColumn) {
      newColumns.splice(newColumns.indexOf(targetColumn), 1);
      // newColumns.unshift(targetColumn);
      newColumns.splice(1, 0, targetColumn)
    }
    return newColumns;
  };
  //避免分页被截断
  const exportPDF = async (pdfDom, title) => {
    // html2pdf
    let element = document.getElementById(pdfDom);
    if (element) {
      setSpinning(true)
      // message.loading({ content: '正在下载报告...', key });
      await html2pdf().from(element).set({
        filename: `${title}.pdf`,//导出pdf标题
        image: {type: 'jpeg', quality: 1},
        html2canvas: {scale: 2, logging: true, dpi: 300, letterRendering: true, useCORS: true,},
        height: 841.89,
        jsPDF: {
          unit: 'mm',
          format: [452.28, 841.89],//页面大小，这里自适应了内容的高度
          orientation: 'portrait',// 页面方向，portrait: 纵向，landscape: 横向
          compressPDF: false, // 启用PDF内容压缩以适应页面
          putOnlyUsedFonts: true, // 仅包括使用的字体以减小文件大小
          autoPaging: true, // 启用自动分页
          precision: 16, // 浮点数的精度
          allowTaint: true,//允许跨域
          fontStyle: 'normal', // 或 'italic'，根据需要
          foreignObjectRendering: true,
        },
      }).save()
      setSpinning(false)
    }
  }
  const downloadPdf = async (name) => {
    let userInfo = localStorage.getItem('pca_user')? JSON.parse(localStorage.getItem('pca_user')) : {}
    window._czc.push(["_trackEvent", "下载报告",userInfo.companyName+userInfo.name,name,window.location?.href]);
    const hide = message.loading('正在下载报告，请稍后..', 0, () => {
      message.success('下载完成');
    });
    const result = await api.downloadPdfByCompanyId({companyId: companyId,qualityTime:props.time})
    if(result.data){
      let url = result.data
      const link = document.createElement('a') // 创建一个a元素
      link.href = url // 设置a元素的目标url
      link.download = name + '.pdf' // 设置下载的文件名。该属性的意思是当点击时不是导航到目标url而是下载，接受一个文件名。
      link.style.display = 'none' // 设置a元素不可见
      document.body.appendChild(link) // 将a元素插入到页面中
      link.click() // 触发a元素的点击事件，实现下载
      document.body.removeChild(link) // 在页面中删除a元素
      hide()
    }
  }

  return <Watermark content={watermark} font={{fontSize: 26}} rotate={-45}>
    <div className={s.container} id="pdfContainer">
      <div className={s.download} data-html2canvas-ignore="true">
        <Button type="primary" onClick={() => {
          downloadPdf(props.data?.entityName)
        }} className={s.downloadBtn}><VerticalAlignBottomOutlined/>下载报告</Button>
        <Button style={{display: 'none'}} type="primary" onClick={() => {
          exportPDF('pdfContainer', props.data?.entityName)
        }} id="downloadPdf" className={s.downloadBtn} loading={spinning}><VerticalAlignBottomOutlined/>下载报告</Button>
        <Button style={{marginLeft: '10px'}} onClick={() => {
          window.history.back(-1)
        }}>返回</Button>
      </div>
      <div className={s.table}>
        <div className={[s.tr, s.tableTitle].join(' ')}>
          <div className={s.td}>公司名称</div>
          <div className={s.td}>主体信用质量</div>
          <div className={s.td}>日期</div>
          <div className={s.td}>分析师</div>
          <div className={s.td}>联系方式</div>
        </div>
        <div className={s.tr}>
          <div className={s.td}>{props.data?.entityName}</div>
          <div className={s.td}>{props.data?.quality}</div>
          <div className={s.td}>{props.data?.reportDate}</div>
          <div className={s.td}>{props.data?.doc.analystName}</div>
          <div className={s.td}>{props.data?.doc.analystEmail}</div>
        </div>
      </div>
      <div className={s.detailContent}>
        <div className={s.assessment}>
          <div className={s.title}>分项评估</div>
          <div className={s.content}>
            <div className={s.left}>
              {
                listData.map((item, index) => {
                  return <div key={index} className={s.line} style={{fontWeight: item.sub ? 'normal' : 'bold'}}>
                    <span style={{textAlign: 'left', textIndent: item.sub ? '10px' : '0'}}>{item.center}：</span>
                    <span>{item.top}</span>
                  </div>
                })
              }
            </div>
            <div className={s.right}>
              <div class={s.rightTitle} style={{borderBottom: '1px solid #e9e9e9'}}>调整项明细</div>
              {
                impact.map((item, index) => {
                  return <div key={index} className={s.line}>
                    <span>{item.name}：</span>
                    <span>{item.val}</span>
                  </div>
                })
              }
            </div>
          </div>
          {
            imageUrl ? <img src={imageUrl} alt="" style={{width: '100%', marginTop: '20px'}}/> :
              <ReactEcharts
                ref={getEchartsRef}
                option={getChartOptions()}      // option：图表配置项
                notMerge={true}
                lazyUpdate={true}
                style={{height: '320px', marginTop: '20px'}}
              />
          }
          <div style={{marginTop: '10px', padding: '0 10px 10px'}}>
            <div className={s.desc}>
              注:本报告中所呈现的主体信用质量分布是我们根据公开信息，通过案头分析所得出对于信用质量的初步观点。标普信评未与任何企业进行访谈或其他任何形式的互动沟通，也未通过标普信评的信用评级流程例如信用评审委员会加以审核与处理本报告所呈现的潜在信用质量分布。本报告中表达的观点不可也不应被表述为信用评级，也不应被视为对任何企业最终评审结果的表示。颜色含义如下:
            </div>
            <div className={s.desc}>
              深绿色(aaa1至aaa5):偿还债务的能力极强，基本不受不利经济环境的影响，违约风险极低；
            </div>
            <div className={s.desc}>绿色(aa序列):偿还债务的能力很强，受不利经济环境的影响不大，违约风险很低；</div>
            <div className={s.desc}>浅绿色(a序列):偿还债务能力较强，较易受不利经济环境的影响，违约风险较低；</div>
            <div className={s.desc}>黄色(bbb序列):偿还债务能力一般，受不利经济环境影响较大，违约风险一般；</div>
            <div className={s.desc}>红色(bb序列):偿还债务能力较弱，受不利经济环境影响很大，有较高违约风险；</div>
            <div className={s.desc}>紫色(b序列 及以下):偿还债务的能力较大地依赖于良好的经济环境，违约风险很高。</div>
            <div className={s.desc}>资料来源:标普信评</div>
            <div className={s.desc}>版权@2023标普信用评级(中国)有限公司。版权所有。</div>
          </div>
          {
            data?.doc?.industryQuantile && <div>
              <div style={{textAlign: 'center', fontSize: '16px', fontWeight: 'bold'}}>主体信用质量</div>
              <div className={s.title} style={{display: 'flex', marginTop: '10px'}}>额外指标</div>
              <div className={s.tableNoFlex}>
                {
                  extra.map((item, index) => {
                    return <div className={s.tr} key={index}
                                style={index === 0 ? {width: '100%', fontWeight: 'bold'} : {}}>
                      <div className={s.td}>{item.name === 'doc' ? industries : item.name}</div>
                      <div className={s.td}>{formatNumberWithCommas(item.the)}</div>
                      <div className={s.td}>{formatNumberWithCommas(item.two)}</div>
                      <div className={s.td}>{formatNumberWithCommas(item.five)}</div>
                      <div className={s.td}>{formatNumberWithCommas(item.seven)}</div>
                    </div>
                  })
                }
              </div>
            </div>
          }
        </div>
        <div className={s.indicators}>
          <div className={s.title} style={{display: 'flex'}}>关键指标</div>
          {
            basicData && <div className={s.table} style={{width: 'auto'}}>
              <div className={s.noTr} style={{borderRight: 'none'}}>
                {
                  getKeyIndicatorscolumns1(basicData).map((item, index) => {
                    return <div className={s.td} key={index} style={{width: '33%'}}>{item.title}</div>
                  })
                }
              </div>
              {
                getKeyIndicatorsTableData1(basicData).map((item, index) => {
                  return <div className={s.tr} key={index} style={{borderRight: 'none'}}>
                    {
                      getKeyIndicatorscolumns1(basicData).map((demo, idx) => {
                        return <div className={s.td} key={idx}
                                    style={{width: '33%'}}>{formatNumberWithCommas(item[demo.dataIndex])}</div>
                      })
                    }
                  </div>
                })
              }
            </div>
          }
          <div className={s.tips} style={{
            display: 'flex',
            borderRight: 'none'
          }}>分析师在分析时点没有得到最新财务年实际财务数据的情况下，将会使用财务数据估计值计算相关指标并在该年份标注“E(比如2021E则代表该年度财务数据为估计值)
          </div>
          <div style={{padding: '0 10px'}}>
            {
              analyticalTextData.map((item, index) => {
                return <div key={index} style={{marginTop: '10px', marginBottom: '12px'}}>
                  <span style={{fontWeight: 'bold'}}>{item.title}：</span>
                  <span dangerouslySetInnerHTML={{__html: item.contentBase64}} style={{whiteSpace: 'pre-wrap'}}></span>
                </div>
              })
            }
          </div>
        </div>
      </div>
      <div className={s.table} style={{marginTop: '10px'}}>
        <div className={[s.tr, s.tableTitle].join(' ')}>
          {
            columns.map((item, index) => {
              return <div className={s.td} key={index}>{item.title}</div>
            })
          }
        </div>
        {
          tableData.map((item, index) => {
            return <div className={s.tr} key={index}>
              {
                columns.map((demo, idx) => {
                  return <div className={s.td} key={idx}>{item[demo.dataIndex]}</div>
                })
              }
            </div>
          })
        }
        <div className={s.tips}>{props.data?.doc.peerEntityFinancialDataComment}</div>
      </div>
      <div className={s.bqTips}>
        <div>©版权所有2024标普信用评级（中国）有限公司。保留所有权利。</div>
        <br/>
        <div>本非公开信用分析基于贵司与标普信用评级（中国）有限公司（简称“标普信评”）签订的委托协议，和/或基于贵司和标普信评关联方就通过该关联方平台获取本非公开信用分析所签订的协议及其中的条款和条件而提供。本非公开信用分析仅限在条款和条件中明确允许的情况下进行传播。本非公开信用分析结果仅基于公开渠道收集的资料，受评对象未参与且不涉及受评对象的内部文件(除非该等受评对象的内部文件由贵司依据委托协议提供给标普信评)和/或与受评对象管理层的互动沟通。我们根据我们的政策和流程确定的标准使用了来源可靠的资料，但不保证所用资料的正确性、充分性和完整性。本非公开信用分析结果在中国境内不得用于任何监管目的，亦不得用于任何融资活动，包括但不限于任何债券发行或者资金筹集活动。本非公开信用分析结果报告不得作为任何债券或证券发行文件的一部分被披露，不得在募集说明书、其他债券发行文件中或任何披露文件中被援引或提及，不得为投资人或其他任何人士所依赖。本非公开信用分析结果不可等同于或表述为标普信评受委托授予的信用评级，也不得视为标普全球评级观点。标普信评对授予、更新或撤销任何非公开信用分析结果拥有绝对酌情决定权。</div>
        <br/>
        <div>标普信评拥有上述内容（包括评级、信用相关的分析和数据、估值、模型、软件或其他应用或其中的输出）或其任何部分（简称“内容”）的版权和
          或其他相关知识产权。未经标普信评或其关联方的事先书面许可，严禁以任何形式或方式修改、逆向工程、复制或发布任何内容，或将任何内容存储在数据库或检索系统中。内容不得用于任何非法或未经授权的目的。标普信评和任何第三方供应商，以及其董事、管理人员、股东、员工或代理人（统称“标普方”）均不保证内容的准确性、完整性、及时性或可用性。标普方不对由任何原因导致的错误或遗漏（疏忽或其他）、因使用内容而获得的结果、或用户输入数据的安全性或维护该等数据承担责任。内容基于“现状”提供。标普方特此声明不做出任何明示或默示保证，包括但不限于对适销性或特定用途或使用目的的保证，以及对不存在漏洞、不存在软件错误或缺陷，以及内容的功能将不会中断或内容将与任何软件或硬件配置兼容等方面的保证。在任何情形下，标普方将不对任何人就与使用任何内容相关的任何直接、间接、附带、惩罚、补偿、惩戒、特殊或后续的损害、费用、开支、律师费或损失（包括且不限于收入损失、利润损失以及因疏忽造成的机会成本和损失）承担责任，即使标普方已经知道发生类似损害的可能性。
        </div>
        <br/>
        <div>信用相关的分析和其他分析（包括评级和内容中的陈述）是截至发表之日的意见陈述，而非事实陈述。标普信评的意见、分析和评级确认决策（如下所述）并非且不应被视为购买、持有或出售任何证券或作出任何投资决策的建议，也不涉及任何证券的适合性。在发布后，标普信评不承担更新（不论以任何形式或格式）发布内容的义务。在进行投资和其他业务决策时，不应依赖内容，内容也无法取代用户、其管理层、员工、顾问和或客户的技能、判断和经验。标普信评不作为受托人或投资顾问，除非其注册为该类机构。虽然标普信评从其认为可靠的渠道获取信息，但标普信评不审计其获得的信息，也不承担相关的尽职调查义务或独立验证义务。与评级相关的出版物可能由于各种原因发布，这些原因不一定取决于评级委员会的行动，例如发布定期更新的信用评级和相关分析。</div>
        <br/>
        <div>标普信评并不属于标普全球评级身为国家认可统计评级机构（NRSRO）的联属企业。标普信评根据在中国专用的评级等级体系授予评级，所授予的评级是标普信评对于债务人相对于中国境内其他发行人的整体资信或对特定债务的偿债能力的意见，并提供在中国境内信用风险的排序。标普信评所授予的评级并非根据全球评级等级体系所授予的评级，不可也不应被视为或不实地表述为全球评级等级体系下授予的评级，或者作为全球评级等级体系下授予的评级而加以依赖。标普方不为违反本段使用标普信评的评级所产生的任何损失负责。</div>
        <br/>
        <div>如果监管机构允许评级机构在一个司法辖区内因某些监管目的承认在另一个司法辖区发布的评级，标普信评保留随时自行决定授予、撤销或中止此类承认的权利。标普信评特此声明不对因授予、撤销或中止承认而产生的任何责任以及宣称因此而产生的任何损害负责。</div>
        <br/>
        <div>标普信评将其不同业务单位的活动保持分离，以保持相应活动的独立性和客观性。因此，标普信评的某些业务单位可能拥有其他业务单位所没有的信息。标普信评制定了政策和程序，以确保对各个分析过程中相关的特定非公开信息予以保密。</div>
        <br/>
        <div>标普信评可能从其评级和特定分析活动中获得报酬，报酬一般由证券发行人或承销人或者债务人支付。标普信评保留发布其意见和分析的权利。标普信评的公开评级和分析公布在其网站上 <a
          href="www.spgchinaratings.cn">www.spgchinaratings.cn</a> 并且可以通过其他方式发布，包括但不限于标普信评出版物和第三方转销商。
        </div>
        <br/>
        <div>非公开且保密：本非公开信用分析报告的有效性截至报告出具日期。标普信评自该日期起未对该非公开信用分析进行审阅，当前分析或存在差异。</div>
      </div>
    </div>
  </Watermark>
}

export default OldDetail