import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {ConfigProvider} from "antd";
// 语言汉化
import zhCN from "antd/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import store from "./redux";
import {Provider} from "react-redux";
import {HashRouter} from "react-router-dom";
import '@/styles/global.scss';
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "@/dev";

dayjs.locale("zh-cn");

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <HashRouter>
    <ConfigProvider locale={zhCN}>
      <Provider store={store}>
        <DevSupport ComponentPreviews={ComponentPreviews}
                    useInitialHook={useInitial}
        >
          <App/>
        </DevSupport>
      </Provider>
    </ConfigProvider>
  </HashRouter>
);