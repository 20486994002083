import React,{useEffect} from 'react'
import { useRoutes } from 'react-router-dom'
import routes from './routers/index'
const App = () => {
  // 获得路由表
  const routeView = useRoutes(routes)

  useEffect(() => {
    const handleClick = (event) => {
      if(event){
        let userInfo = localStorage.getItem('pca_user')? JSON.parse(localStorage.getItem('pca_user')) : {}
        window._czc.push(['_setUUid', userInfo.phoneNumber])
        let companyName = sessionStorage.getItem('companyName')
        console.log("_trackEvent", "事件跟踪",userInfo.companyName+userInfo.name,event?.target?.innerText,event?.view?.location?.href)
        window._czc.push(["_trackEvent", "事件跟踪",userInfo.companyName+userInfo.name,event?.target?.innerText,event?.view?.location?.href]);
      }
    };

    // 添加点击事件监听器到document
    document.addEventListener('click', handleClick);

    // 组件卸载时移除事件监听器
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []); // 空依赖数组确保只在组件挂载时添加监听器一次
  return (
    <>
      {routeView}
    </>
  )
}

export default App