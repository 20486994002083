import service from "@/utils/request"

const credit = {
  // 获取企业详情
  getCompanyDetail(params) {
    return service.request({ url: "/company/getInfo", method: "get", params })
  },
  // 获取信用分析详情
  getCreditAnalysisDetail(params) {
    return service.request({ url: "/entityReport/getRecordDetail", method: "get", params })
  },
  //获取信息
  getCompanyAdvancedInfo(params) {
    return service.request({url:'/company/getAdvancedInfo',method:"get",params})
  },
  //获取评级变动
  getCompanyQualityChange(params) {
    return service.request({url:"/entityReport/getQualityChange",method:"get",params})
  },
  //获取评级质量分布图
  getCompanyQualityDistribution(params) {
    return service.request({url:"/company/getQualityDistribution",method:"get",params})
  },
  //获取新闻列表
  getCompanyOpinionList(params) {
    return service.request({url:"/publicOpinion/getPageByCompanyId",method:'get',params})
  },
  //下载报告
  downloadPdfByCompanyId(params) {
    return service.request({url:"pdf/downloadByOss",method:'get',params})
  }
  
};

export default credit;