import React, { useState } from 'react'
import style from './index.module.scss'
import FlowCpn from './components/flowCpn'
import CustomModal from '../../../../components/CustomModal'
const ChartProcess = (props) => {
  const {data={}} = props
  const [showModal,setShowModal] = useState(false)
  const handleCancel = () => {
    setShowModal(false)
  }
  const handleOk = () => {
    setShowModal(false)
  }
  return <div className={style.chartProcess_container}>
    <div className={style.chartProcess_wrapper}>
      <div className={style.chartProcess_title}>
        <div className={style.chartProcess_title_main}>PCA信用分析框架</div>
        <div className={style.chartProcess_title_link} onClick={() => {
          setShowModal(true)
        }}>查看本行业分析</div>
      </div>
        <div className={style.chartProcess_flowCpn}>
          <FlowCpn chooseZb={props.chooseZb} sector={props.sector} isFIType={props.isFIType} data={data} changeCurrentSelectId={props.changeCurrentSelectId} flowData={props.flowData} flowLoading={props.flowLoading}/>
        </div>
    </div>
    <CustomModal handleCancel={handleCancel} handleOk={handleOk} isModalOpen={showModal} wechatQRCode={true} otherTips={"请联系工作人员获取行业分析服务权限。"}/>
  </div>
}

export default ChartProcess