import React from "react";
import style from "./index.module.scss";
import {HistoryOutlined} from '@ant-design/icons'

const IntroContent = (props) => {
  const {data = {}, newData = {}} = props;
  return (<div className={style.introContent_container}>
    <div className={style.newCompany}>
      <span className={style.companyName}>{newData?.name}</span>
      <span>
                <HistoryOutlined style={{marginRight: '5px'}}/>
                更新时间：{data?.reportDate}
            </span>
    </div>
    <div className={style.companyList}>
      <div className={style.item}>
        <span>非公开信用分析结果</span>
        <span style={{color: '#dc042c'}}>{data?.quality}</span>
      </div>
      {(data?.doc?.industryName || data?.doc?.industrySubcategory) && <div className={style.item}>
        <span>行业分类</span>
        <span>{data?.doc?.industryName || data?.doc?.industrySubcategory}</span>
      </div>}
      <div className={style.item}>
        <span>行业风险</span>
        <span>{newData.industriesValue}<span className={style.noStyle}>{newData.industriesUnits}</span></span>
      </div>
      <div className={style.item}>
        <span>主体信用质量优于</span>
        <span>
                    {newData?.overPercent}
          <span className={style.noStyle}>同业主体</span>
                </span>
      </div>
      <div className={style.item}>
        <span>半年内主体信用质量变动</span>
        <span>
                    {newData?.qualityChange ? newData?.qualityChange : '暂无'}
                </span>
      </div>
      <div className={style.item}>
        <span>相关舆情点评</span>
        <span>
                    {newData?.publicOpinionNum ? newData?.publicOpinionNum + '条' : '暂无'}
                </span>
      </div>
    </div>
  </div>);
};

export default IntroContent;