import React, {useEffect, useState, useRef} from 'react'
import {deepCopy, isArray, isObject, formatNumberWithCommas, scrollToTop, removeCommas} from '@/utils'
import api from '@/api'
import ReactEcharts from 'echarts-for-react'
import {decode} from 'js-base64'
import {Table, Button, Spin, message, Watermark} from 'antd'
import {VerticalAlignBottomOutlined} from '@ant-design/icons'
import html2canvas from "html2canvas";
import axios from 'axios'
import ApiConstants from '@/constants/apiConstants'
import html2pdf from "html2pdf.js";
import downloadImg from '@/assets/images/queryView/downloadf.png'
import jsPDF from "jspdf"
import s from './index.module.scss'

let isSmillScreen = false
const FI_LEFT = [
  {title: '评级基准', variable: 'anchor'},
  {title: '业务状况', variable: 'businessProfile'},
  {title: '资本与盈利性', variable: 'capitalProfitability'},
  {title: '风险状况', variable: 'riskProfile'},
  {title: '融资与流动性', variable: 'financingLiquidity'},
  {title: '补充调整', variable: 'additionalAdjustment'},
  {title: '个体信用质量', variable: 'sacp'},
  // {title:'支持方的信用质量', variable:'supportIcrOrSacp'},
  // {title:'外部支持重要性', variable:'externalSupportImportance'},
  {title: '外部影响', variable: 'finalNotching'},
  {title: '未经调整集团信用质量（如适用）', variable: 'unadjustedSacp'},
  {title: '控股公司的特有风险因素（如适用）', variable: 'fhcRisk'},
], CORP_LEFT = [
  {title: '业务/经营状况', variable: 'brp'},
  {title: '行业名称', variable: 'industryName'},
  {title: '行业风险', variable: 'industryRisk'},
  {title: '竞争地位', variable: 'competitivePosition'},
  {title: '财务风险', variable: 'frp'},
  {title: '评级基准', variable: 'anchor'},
  {title: '个体信用质量', variable: 'sacp'},
  {title: '外部影响', variable: 'externalImpact'},
  {title: '主体信用评级结果', variable: 'icr'},
]
const OldDetail = (props) => {
  const data = props.data
  const getEchartsRef = useRef(null);
  const key = 'updatable1';
  const watermark = [JSON.parse(localStorage.getItem('pca_user')).companyName + '--' + JSON.parse(localStorage.getItem('pca_user')).name, JSON.parse(localStorage.getItem('pca_user')).phoneNumber]
  const [spinning, setSpinning] = useState(false)
  const basicData = props.data || null
  const [columns, setColumns] = useState([])
  const [dataSource, setDataSource] = useState({})
  const [tableData, setTableData] = useState([])
  const [sector, setSector] = useState('');
  const [chartsOptions, setChartsOptions] = useState({});
  const [chooseZb, setChooseZb] = useState(false)
  const [analyticalTextData, setAnalyticalTextData] = useState([])
  const [currentFlowName, setCurrentFlowName] = useState('个体信用质量')
  const [impact, setImpact] = useState([])
  const [isFIType, setIsFIType] = useState(false);
  const [chartKey, setChartKey] = useState(true);
  const [currentAllData, setCurrentAllData] = useState({});
  const [listData, setListData] = useState([])
  const [currentRardaData, setCurrentRardaData] = useState([]);
  const [echartsData, setEchartsData] = useState([])
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    setSector(props?.data?.sector)
    scrollToTop()
  }, [])

  const getEcharts = async (copyData) => {
    let result = await api.getQualityDistributionFI({sector: copyData?.sector})
    if (result?.code == 200) {
      setEchartsData(result.data)
    }
  }
  const yewuzhuangkuang = () => {
    let options = {
      title: {
        text: '业务状况',
        left: "center",
        top: 0
      },
      tooltip: {},
      legend: {
        show: true
      },
      grid: {
        left: "50px", right: "50px", bottom: '30px'
      },
      xAxis: {
        data: ["+2", "+1", "0", "-1", "-2", "-3"],
      },
      yAxis: {
        show: false,
      },
      series: [
        {
          type: "bar",
          data: filterData(data),
        },
      ],
    }
    return options;
  }
  const labelFormatter = (params, type = 1, row) => {
    let _index = type - 1
    const currentData = props.data?.doc?.entityInformationDisclosureIndicator?.financialIndicator
    switch (true) {
      case type == 1 :
        if ((parseInt(currentData[_index].indicatorValue) <= 500 && parseInt(params.name.split(',').join('')) == 500 && params.componentIndex == 1) || (parseInt(currentData[_index].indicatorValue) > parseInt(params.name.split(',').join('')) && currentData[_index].indicatorValue < getBarArea(params.name) && (params.componentIndex == 1 || parseInt(params.name.split(',').join('')) > 1500))) return props.data?.doc?.entityShortName
        return ''
      case type == 2 :
        if (parseInt(params.name) == parseInt(currentData[_index].indicatorValue) || (parseInt(currentData[_index].indicatorValue) <= 0 && params.name == 3 && params.componentIndex == 1)) return props.data?.doc?.entityShortName
        return ''
      case type == 3 :
        if (currentData[_index].indicatorValue > params.name && currentData[_index].indicatorValue < (parseFloat(params.name) + 0.199)) return props.data?.doc?.entityShortName
        return ''
      case type == 4 :
        if (currentData[_index].indicatorValue > params.name && currentData[_index].indicatorValue < (parseFloat(params.name) + 0.199)) return props.data?.doc?.entityShortName
        return ''
      case type == 5 :
        if (currentData[_index].indicatorValue > parseInt(params.name) && currentData[_index].indicatorValue < (parseInt(params.name) + 9.999)) return props.data?.doc?.entityShortName
        return ''
      case type == 6 :
        if (currentData[_index].indicatorValue > parseInt(params.name) && currentData[_index].indicatorValue < (parseInt(params.name) + 4.999)) return props.data?.doc?.entityShortName
        return ''
      case type == 7 :
        if (row) {
          if (params.name === props.data?.doc?.sacp && params.componentIndex == 3) return props.data?.doc?.entityShortName
        } else {
          if (params.name === props.data?.doc?.icr) return props.data?.doc?.entityShortName
        }

        return ''
      case type == 8 :
        if (row) {
          if (params.name === props.data?.doc?.quality && params.componentIndex == 3) return props.data?.doc?.entityShortName
        } else {
          if (params.name === props.data?.doc?.quality) return props.data?.doc?.entityShortName
        }
        return ''
      default:
        return ''
    }
  }
  const getBarArea = (cur) => {
    const _cur = parseInt(cur.split(',').join(''))
    switch (true) {
      case _cur <= 1500:
        return _cur + 500
      case _cur >= 2000 && _cur < 8000:
        return _cur + 2000
      case _cur >= 10000 && _cur < 50000:
        return _cur + 5000
      default:
        return _cur
    }
  }
  const getChartOptions = (type) => {
    let cData = []
    cData = type === 2 ? echartsData?.qualityDistributionFIVOList || [] : echartsData?.sacpDistributionFIVOList || []
    let x = {}
    let xAxis = []
    let yAxis = []
    cData.map(item => {
      if (!x.hasOwnProperty(item.industries)) x[item.industries] = []
      if (xAxis.indexOf(item.quality) < 0) xAxis.push(item.quality)
      x[item.industries].push(item)
    })

    for (let item in x) {
      let seriesData = x[item].reduce((pre, el, index) => {
        pre.push(el.qualityNum);
        return pre;
      }, [])
      yAxis.push(
        {
          name: item,
          type: 'bar',
          stack: 'Ad',
          data: seriesData,
          label: {
            show: true,
            position: 'top',
            formatter: (params) => (type === 2 ? labelFormatter(params, 8, 2) : labelFormatter(params, 7, 2))
          }
        })
    }
    let options = {
      title: {
        text: type == 2 ? '600家主要金融机构主体信用质量测评结果分布示意' : '600家主要金融机构个体信用质量测评结果分布示意',
        left: "center",
        top: 0
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        orient: 'vertical',
        right: '10px',
        top: '35%'
      },
      grid: {
        left: '3%',
        right: '20%',
        bottom: '3%',
        top: '20%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: xAxis
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: yAxis
    };
    return options;
  }
  const filterData = (data) => {
    return echartsData.map((item, index) => {
      return {
        key: index,
        value: item.qualityNum,
        itemStyle: {
          color: getColor(index),
        }
      }
    });
  }
  const getColor = (number = 0) => {
    let num = number + 1;
    switch (true) {
      case num >= 1 && num <= 5:
        return '#0a6b5b';
      case num >= 6 && num <= 9:
        return '#06927c';
      case num >= 7 && num <= 9:
        return '#08c3a5';
      case num >= 10 && num <= 12:
        return '#08c3a5';
      case num >= 13 && num <= 15:
        return '#ffac17';
      case num >= 16 && num <= 18:
        return '#d6002a';
      default:
        return '#c40067'
    }
  }
  const handleEchartsToImage = () => {
    if (getEchartsRef.current) {
      // 获取 Echarts 实例
      const echartsInstance = getEchartsRef.current.getEchartsInstance();
      // 使用 getDataURL 方法生成图片的 base64 URL
      const imageUrl = echartsInstance.getDataURL({
        type: 'png',
        pixelRatio: 2,
        backgroundColor: '#fff',
      });
      setImageUrl(imageUrl)
    }
  };
  const replaceNewlinesWithTwo = (text) => {
    let configContent = text.replace(/^[\r\n]+/, '').replace(/\r/g, '\n')
    return configContent.replace(/\n+/g, '\n\n')
  }
  useEffect(() => {
    const copyData = deepCopy(props.data)
    if (copyData) {
      setIsFIType(props.data?.sector == "金融机构");
      getEcharts(copyData)
      let config = getCompanyInfoData('left', copyData?.sector == '金融机构')
      setListData(config)
      const configImpact = []
      for (let item in copyData?.doc.adjustmentItem) {
        configImpact.push({name: item, val: copyData?.doc.adjustmentItem[item]})
      }
      setImpact(configImpact)
      const analyticalText = copyData?.doc.analyticalText
      let arr = []
      if (analyticalText && analyticalText.length > 0) {
        analyticalText.map(item => {
          if (item.contentInBase64) {
            let _str = decode(item.contentInBase64)
            _str = _str.replace(/\r\n/g, '\n')
            if (_str.slice(0, 1) == "\n") {
              _str = _str.replace(/\n/, '')
            }
            arr.push({title: item.title, contentBase64: replaceNewlinesWithTwo(_str)})
          }
        })
      }
      setAnalyticalTextData(arr)
      const selfData = copyData?.doc?.entityFinancialData
      const diffData = copyData?.doc?.peerEntityFinancialData || copyData?.doc?.peerFinancialData || []
      let yearStr = ''
      if (isArray(selfData) && selfData?.length > 0) {
        // yearStr = selfData.slice(0,2).map((item) => {
        //   return item['年份']
        // }).join('/')
        yearStr = [...selfData, ...diffData].reduce((acc, cur) => {
          if (!acc[cur.entityId]) {
            acc[cur.entityId] = cur['年份']
            return acc
          } else {
            acc[cur.entityId] = acc[cur.entityId] + '/' + cur['年份']
            return acc
          }
        }, {})
        const newData = [...selfData, ...diffData].reduce((acc, cur) => {
          if (!acc[cur.entityId]) {
            let obj = {}
            obj[cur.entityId] = cur
            obj[cur.entityId]['年份'] = yearStr[cur.entityId]
            return Object.assign({}, acc, obj)
          } else {
            acc[cur.entityId].financialIndicator = acc[cur.entityId].financialIndicator.map((item, index) => {
              item.indicatorValue = (Number(removeCommas(item.indicatorValue)) + Number(removeCommas(cur.financialIndicator[index]?.indicatorValue))) / 2
              return item
            })
            return acc
          }
        }, {})
        getKeyIndicatorsColumns(deepCopy(newData), 'normal', copyData?.doc?.entityId)
        getKeyIndicatorsTableData(deepCopy(newData), 'normal')
      } else if (isObject(selfData) && selfData.financialIndicator.length > 0) {
        yearStr = [...[selfData], ...diffData].reduce((acc, cur, index) => {
          acc[index] = cur['财务指标年度']
          return acc
        }, {})
        const result = [selfData];
        const newData = [...result, ...diffData].reduce((acc, cur, index) => {
          // 没有 entityId 的情况下
          let obj = {}
          obj[cur['中文全称']] = cur
          obj[cur['中文全称']]['财务指标年度'] = yearStr[index]
          return Object.assign({}, acc, obj)
        }, {})
        getKeyIndicatorsColumns(deepCopy(newData), 'special', copyData?.doc?.entityName)
        getKeyIndicatorsTableData(deepCopy(newData), 'special')
      }
    }
  }, [props.data])
  const getCompanyInfoData = (type, isFIType) => {
    const FI_LEFT = [
      {title: '业务风险', variable: 'businessRisk'},
      {title: '行业风险', variable: 'riskScore', sub: 1},
      {title: '竞争地位', variable: 'competitivePosition', sub: 1},
      {title: '财务风险', variable: 'financialRisk'},
      {title: '资本与盈利性', variable: 'capitalProfitability', sub: 1},
      {title: '风险状况', variable: 'riskProfile', sub: 1},
      {title: '财务灵活性', variable: 'financialFlexibility', sub: 1},
      {title: '评级基准', variable: 'anchor'},
      {title: '调整项：管理与治理', variable: 'adjustment0', sub: 1},
      {title: '调整项：流动性', variable: 'adjustment1', sub: 1},
      {title: '补充调整', variable: 'adjustment2', sub: 1},
      {title: '个体信用质量', variable: 'sacp'},
      {title: '外部影响', variable: 'externalSupportLikelihood'},
      {title: '未经调整集团信用质量（如适用）', variable: 'unadjustedSacp', sub: 1},
      {title: '控股公司的特有风险因素（如适用）', variable: 'fhcRisk', sub: 1},
      {title: '非公开主体信用分析结果', variable: 'quality'}
    ], CORP_LEFT = [
      {title: '业务/经营状况', variable: 'brp'},
      {title: '行业划分', variable: 'industryName'},
      {title: '行业风险', variable: 'industryRisk', sub: 1},
      {title: '竞争地位', variable: 'competitivePosition', sub: 1},
      {title: '财务风险', variable: 'frp'},
      {title: '评级基准', variable: 'anchor'},
      {title: '调整项', variable: 'adjustmentSum'},
      {title: '个体信用质量', variable: 'sacp'},
      {title: '外部影响', variable: 'externalImpact'},
      {title: '主体信用质量', variable: 'quality'},
    ]
    const renderArr = []
    if (type == 'left') {
      (isFIType ? FI_LEFT : CORP_LEFT).map(item => {
        if (data?.doc?.[item.variable]) renderArr.push({
          top: data.doc[item.variable],
          center: item.title,
          sub: item.sub ? item.sub : 0
        })
      })
    } else {
      if (data?.doc?.adjustmentItem) {
        Object.keys(data.doc.adjustmentItem).map(key => renderArr.push({
          top: data.doc.adjustmentItem[key],
          center: key
        }))
      }
    }
    return renderArr
  }
  // 关键指标数据处理
  const getKeyIndicatorsColumns = (data = [], type, ownID) => {
    const newData = Object.values(data)
    let columns = []
    if (type === 'normal') {
      columns = newData.map((item) => {
        return {
          title: item['年份'],
          dataIndex: item.entityId,
          key: item.entityId,
        }
      })
    } else if (type === 'special') {
      columns = newData.map((item) => {
        return {
          title: item['财务指标年度'],
          dataIndex: item['中文全称'],
          key: item['中文全称'],
        }
      })
    }
    columns.unshift({
      title: props?.data?.sector === '工商企业' ? '同业比较（两年平均）' : '同业比较',
      dataIndex: 'comparative',
      key: 'comparative'
    })
    const newColumns = moveColumnToFirst(ownID, columns)
    setColumns(newColumns)
  }
  const getKeyIndicatorsTableData = (data = [], type) => {
    const newData = Object.values(data)
    if (type === 'normal') {
      setTableData(transformData(newData))
    } else if (type === 'special') {
      setTableData(transformData_special(newData))
    }
  }

  // 转换函数
  const transformData = (input) => {
    const companyNames = {};
    const financialIndicators = [];

    input.forEach(item => {
      // 收集企业名称
      companyNames[item.entityId] = item.企业名称;

      // 收集财务指标
      item.financialIndicator.forEach((indicator, index) => {
        const indicatorIndex = financialIndicators.findIndex(fin => fin.comparative === indicator.indicatorName);
        if (indicatorIndex === -1) {
          financialIndicators.push({
            [item.entityId]: formatNumberWithCommas(indicator.indicatorValue),
            key: index + 1,
            comparative: indicator.indicatorName,
          });
        } else {
          financialIndicators[indicatorIndex][item.entityId] = formatNumberWithCommas(indicator.indicatorValue);
        }
      });
    });

    // 添加第一行企业名称
    const firstRow = {
      key: 0,
      comparative: data?.sector === '工商企业' ? '主体名称' : ' ',
      ...companyNames
    };

    financialIndicators.unshift(firstRow);
    return financialIndicators;
  };

  const transformData_special = (cData) => {
    // 初始化结果数组
    const result = [];

    // 添加银行名称行
    const bankNamesRow = {
      key: 0,
      comparative: " ",
    };

    // 添加银行数据
    cData.forEach(bank => {
      bankNamesRow[bank['中文全称']] = bank['中文简称'];
    });
    if (data?.sector === '工商企业') bankNamesRow.comparative = '主体名称'
    result.push(bankNamesRow);

    // 处理各个财务指标
    const indicators = cData[0].financialIndicator.map(ind => ({
      name: ind.indicatorName,
      values: {}
    }));

    cData.forEach(bank => {
      bank.financialIndicator.forEach(ind => {
        const indicatorEntry = indicators.find(i => i.name === ind.indicatorName);
        if (indicatorEntry) {
          indicatorEntry.values[bank['中文全称']] = ind.indicatorValue;
        }
      });
    });

    // 构建结果
    indicators.forEach((indicator, index) => {
      const indicatorRow = {
        key: index + 1,
        comparative: indicator.name,
      };
      Object.entries(indicator.values).forEach(([bankName, value]) => {
        indicatorRow[bankName] = value;
      });
      result.push(indicatorRow);
    });
    return result;
  }
  const moveColumnToFirst = (columnDataIndex, columns) => {
    const newColumns = columns.slice();
    const targetColumn = newColumns.find((col) => col.dataIndex === columnDataIndex);
    if (targetColumn) {
      newColumns.splice(newColumns.indexOf(targetColumn), 1);
      // newColumns.unshift(targetColumn);
      newColumns.splice(1, 0, targetColumn)
    }
    return newColumns;
  };
  //避免分页被截断
  const exportPDF = async (pdfDom, title) => {
    let element = document.getElementById(pdfDom);
    if (element) {
      // message.loading({ content: '正在下载报告...', key });
      setSpinning(true)
      await html2pdf().from(element).set({
        // margin: [8, 4, 8, 4],//页边距设置
        filename: `${title}.pdf`,//导出pdf标题
        image: {type: 'jpeg', quality: 1},
        html2canvas: {scale: 2, logging: true, dpi: 500, letterRendering: true, useCORS: true,},
        height: 841.89,
        jsPDF: {
          unit: 'mm',
          format: [452.28, 841.89],//页面大小，这里自适应了内容的高度
          orientation: 'portrait',// 页面方向，portrait: 纵向，landscape: 横向
          compressPDF: false, // 启用PDF内容压缩以适应页面
          putOnlyUsedFonts: true, // 仅包括使用的字体以减小文件大小
          autoPaging: true, // 启用自动分页
          precision: 16, // 浮点数的精度
          allowTaint: true,//允许跨域
          fontStyle: 'normal', // 或 'italic'，根据需要
          foreignObjectRendering: true,
        },
      }).save()
      // message.success({ content: '下载成功!', key, duration: 2 });
      setSpinning(false)
    }
  }
  const chartsData = (name) => {
    let allData = props.data?.doc.fiCharts.insuranceChart
    let xAxis = []
    let yAxis = []
    allData.data.map(item => {
      if (item[0] === name) {
        xAxis.push(item[1])
        yAxis.push(item[2])
      }
    })
    let options = {
      title: {
        show: true, //false
        text: name,
        left: "center",
        top: '5%',
        textStyle: {
          color: "#000", //'red'，字体颜色
          fontWeight: "600", //'bold'(粗体) | 'bolder'(粗体) | 'lighter'(正常粗细) ，字体粗细
          fontFamily: "PingFangSC, PingFang SC", //'sans-serif' | 'serif' | 'monospace' | 'Arial' | 'Courier New'
          fontSize: 14, //字体大小
          lineHeight: 12, //字体行高
        },
        textAlign: "auto", //整体（包括 text 和 subtext）的水平对齐
        textVerticalAlign: "auto", //整体（包括 text 和 subtext）的垂直对齐
        padding: 0, //[5,10] | [ 5,6, 7, 8] ,标题内边距
      },
      grid: {
        bottom: 25,
      },
      tooltip: {},
      xAxis: {
        type: "category",
        data: xAxis,
      },
      yAxis: {
        show: false,
      },
      series: [
        {
          data: yAxis,
          type: "bar",
          itemStyle: {
            color: function (record) {
              let text = name === '业务风险' ? props.data?.doc.businessRisk : props.data?.doc.financialRisk
              return text.includes(record.name) ? allData.entityBarColor : allData.barColor
            }
          }
        },
      ],
    };
    return options
  };
  const downloadPdf = async (name) => {
    let userInfo = localStorage.getItem('pca_user')? JSON.parse(localStorage.getItem('pca_user')) : {}
    window._czc.push(["_trackEvent", "下载报告",userInfo.companyName+userInfo.name,name,window.location?.href]);
    const hide = message.loading('正在下载报告，请稍后..', 0, () => {
      message.success('下载完成');
    });
    const result = await api.downloadPdfByCompanyId({companyId: props.companyId,qualityTime:props.time})
    if(result.data){
      let url = result.data
      const link = document.createElement('a') // 创建一个a元素
      link.href = url // 设置a元素的目标url
      link.download = name + '.pdf' // 设置下载的文件名。该属性的意思是当点击时不是导航到目标url而是下载，接受一个文件名。
      link.style.display = 'none' // 设置a元素不可见
      document.body.appendChild(link) // 将a元素插入到页面中
      link.click() // 触发a元素的点击事件，实现下载
      document.body.removeChild(link) // 在页面中删除a元素
      hide()
    }
  }
  return <Watermark content={watermark} font={{fontSize: 26}} rotate={-45}>
    <div className={s.container} id="pdfContainer">
      <div className={s.download} data-html2canvas-ignore="true">
        <Button type="primary" onClick={() => {
          downloadPdf(props.data?.entityName)
        }} className={s.downloadBtn}><VerticalAlignBottomOutlined/>下载报告</Button>
        <Button style={{display: 'none'}} type="primary" onClick={() => {
          exportPDF('pdfContainer', props.data?.entityName)
        }} id="downloadPdf" className={s.downloadBtn} loading={spinning}><VerticalAlignBottomOutlined/>下载报告</Button>
        <Button style={{marginLeft: '10px'}} onClick={() => {
          window.history.back(-1)
        }}>返回</Button>
      </div>
      <div className={s.table}>
        <div className={[s.tr, s.tableTitle].join(' ')}>
          <div className={s.td}>公司名称</div>
          <div className={s.td}>主体信用质量</div>
          <div className={s.td}>行业分类</div>
          <div className={s.td}>日期</div>
          <div className={s.td}>分析师</div>
          <div className={s.td}>联系方式</div>
        </div>
        <div className={s.tr}>
          <div className={s.td}>{props.data?.entityName}</div>
          <div className={s.td}>{props.data?.quality}</div>
          <div className={s.td}>{props.data?.doc.industryCategory}</div>
          <div className={s.td}>{props.data?.reportDate}</div>
          <div className={s.td}>{props.data?.doc.analystName}</div>
          <div className={s.td}>{props.data?.doc.analystEmail}</div>
        </div>
      </div>
      <div className={s.detailContent}>
        <div className={s.assessment}>
          <div className={s.title}>
            <span>分项评估</span>
            <span>分析结果</span>
          </div>
          <div className={s.content}>
            <div className={s.left}>
              {
                listData.map((item, index) => {
                  return <div key={index} className={s.line} style={{fontWeight: item.sub ? 'normal' : 'bold'}}>
                    <span style={{textIndent: item.sub ? '10px' : '0'}}>{item.center}：</span>
                    <span>{item.top}</span>
                  </div>
                })
              }
            </div>
          </div>
        </div>
        <div className={s.indicators}>
          <div className={s.title} style={{display: 'flex'}}>关键指标</div>
          <div style={{
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: 'bold',
            marginTop: '20px'
          }}>{`${data?.doc?.anchor}评级基准金融机构分项评估要素在子级调整情况分布示意`}</div>
          <div style={{display: 'flex', flexWrap: 'wrap'}}>
            <ReactEcharts
              ref={getEchartsRef}
              option={chartsData('业务风险')}
              notMerge={true}
              lazyUpdate={true}
              style={{width: '50%', height: '220px', marginTop: '20px'}}
            />
            <ReactEcharts
              ref={getEchartsRef}
              option={chartsData('财务风险')}
              notMerge={true}
              lazyUpdate={true}
              style={{width: '50%', height: '220px', marginTop: '20px'}}
            />
            <div style={{marginTop: '10px', padding: '0 10px 10px'}}>
              <div className={s.desc}>
                注: 1分表示风险最低.6分风险最高
              </div>
            </div>
          </div>
          <ReactEcharts
            ref={getEchartsRef}
            option={getChartOptions(1)}
            notMerge={true}
            lazyUpdate={true}
            style={{width: '100%', height: '220px', marginTop: '20px'}}
          />
          <ReactEcharts
            ref={getEchartsRef}
            option={getChartOptions(2)}
            notMerge={true}
            lazyUpdate={true}
            style={{width: '100%', height: '220px', marginTop: '20px'}}
          />
          <div style={{marginTop: '10px', padding: '0 10px 10px'}}>
            <div className={s.desc}>
              注*：本报告中所呈现的潜在信用质量分布是我们根据公开信息，通过案头分析所得出对于信用质量的初步观点。
              标普信评未与任何机构进行访谈或其他任何形式的互动沟通，
              也未通过标普信评的信用评级流程例如信用评审委员会加以审核与处理本报告所呈现的潜在信用质量分布（标普信评公开披露了评级结果的机构除外）。
              本报告中表达的观点不可也不应被表述为信用评级，也不应被视为任何机构的最终评级结果的表示。
            </div>
          </div>
        </div>
      </div>
      <div className={s.table} style={{marginTop: '10px'}}>
        <div className={[s.tr, s.tableTitle].join(' ')}>
          {
            columns.map((item, index) => {
              return <div className={s.td} key={index}>{item.title}</div>
            })
          }
        </div>
        {
          tableData.map((item, index) => {
            return <div className={s.tr} key={index}>
              {
                columns.map((demo, idx) => {
                  return <div className={s.td} key={idx}>{item[demo.dataIndex]}</div>
                })
              }
            </div>
          })
        }
        <div className={s.tips}>资料来源：Wind，银行公开信息，标普信评</div>
      </div>
      <div style={{padding: '10px', border: '1px solid #e9e9e9', marginTop: '10px'}}>
        {
          analyticalTextData.map((item, index) => {
            return <div key={index} style={{marginBottom: '12px'}}>
              <span style={{fontWeight: 'bold'}}>{item.title}：</span>
              <span dangerouslySetInnerHTML={{__html: item.contentBase64}} style={{whiteSpace: 'pre-wrap'}}></span><br/>
            </div>
          })
        }
      </div>
      <div className={s.bqTips}>
        <div>©版权所有2024标普信用评级（中国）有限公司。保留所有权利。</div>
        <br/>
        <div>本非公开信用分析基于贵司与标普信用评级（中国）有限公司（简称“标普信评”）签订的委托协议，和/或基于贵司和标普信评关联方就通过该关联方平台获取本非公开信用分析所签订的协议及其中的条款和条件而提供。本非公开信用分析仅限在条款和条件中明确允许的情况下进行传播。本非公开信用分析结果仅基于公开渠道收集的资料，受评对象未参与且不涉及受评对象的内部文件(除非该等受评对象的内部文件由贵司依据委托协议提供给标普信评)和/或与受评对象管理层的互动沟通。我们根据我们的政策和流程确定的标准使用了来源可靠的资料，但不保证所用资料的正确性、充分性和完整性。本非公开信用分析结果在中国境内不得用于任何监管目的，亦不得用于任何融资活动，包括但不限于任何债券发行或者资金筹集活动。本非公开信用分析结果报告不得作为任何债券或证券发行文件的一部分被披露，不得在募集说明书、其他债券发行文件中或任何披露文件中被援引或提及，不得为投资人或其他任何人士所依赖。本非公开信用分析结果不可等同于或表述为标普信评受委托授予的信用评级，也不得视为标普全球评级观点。标普信评对授予、更新或撤销任何非公开信用分析结果拥有绝对酌情决定权。</div>
        <br/>
        <div>标普信评拥有上述内容（包括评级、信用相关的分析和数据、估值、模型、软件或其他应用或其中的输出）或其任何部分（简称“内容”）的版权和
          或其他相关知识产权。未经标普信评或其关联方的事先书面许可，严禁以任何形式或方式修改、逆向工程、复制或发布任何内容，或将任何内容存储在数据库或检索系统中。内容不得用于任何非法或未经授权的目的。标普信评和任何第三方供应商，以及其董事、管理人员、股东、员工或代理人（统称“标普方”）均不保证内容的准确性、完整性、及时性或可用性。标普方不对由任何原因导致的错误或遗漏（疏忽或其他）、因使用内容而获得的结果、或用户输入数据的安全性或维护该等数据承担责任。内容基于“现状”提供。标普方特此声明不做出任何明示或默示保证，包括但不限于对适销性或特定用途或使用目的的保证，以及对不存在漏洞、不存在软件错误或缺陷，以及内容的功能将不会中断或内容将与任何软件或硬件配置兼容等方面的保证。在任何情形下，标普方将不对任何人就与使用任何内容相关的任何直接、间接、附带、惩罚、补偿、惩戒、特殊或后续的损害、费用、开支、律师费或损失（包括且不限于收入损失、利润损失以及因疏忽造成的机会成本和损失）承担责任，即使标普方已经知道发生类似损害的可能性。
        </div>
        <br/>
        <div>信用相关的分析和其他分析（包括评级和内容中的陈述）是截至发表之日的意见陈述，而非事实陈述。标普信评的意见、分析和评级确认决策（如下所述）并非且不应被视为购买、持有或出售任何证券或作出任何投资决策的建议，也不涉及任何证券的适合性。在发布后，标普信评不承担更新（不论以任何形式或格式）发布内容的义务。在进行投资和其他业务决策时，不应依赖内容，内容也无法取代用户、其管理层、员工、顾问和或客户的技能、判断和经验。标普信评不作为受托人或投资顾问，除非其注册为该类机构。虽然标普信评从其认为可靠的渠道获取信息，但标普信评不审计其获得的信息，也不承担相关的尽职调查义务或独立验证义务。与评级相关的出版物可能由于各种原因发布，这些原因不一定取决于评级委员会的行动，例如发布定期更新的信用评级和相关分析。</div>
        <br/>
        <div>标普信评并不属于标普全球评级身为国家认可统计评级机构（NRSRO）的联属企业。标普信评根据在中国专用的评级等级体系授予评级，所授予的评级是标普信评对于债务人相对于中国境内其他发行人的整体资信或对特定债务的偿债能力的意见，并提供在中国境内信用风险的排序。标普信评所授予的评级并非根据全球评级等级体系所授予的评级，不可也不应被视为或不实地表述为全球评级等级体系下授予的评级，或者作为全球评级等级体系下授予的评级而加以依赖。标普方不为违反本段使用标普信评的评级所产生的任何损失负责。</div>
        <br/>
        <div>如果监管机构允许评级机构在一个司法辖区内因某些监管目的承认在另一个司法辖区发布的评级，标普信评保留随时自行决定授予、撤销或中止此类承认的权利。标普信评特此声明不对因授予、撤销或中止承认而产生的任何责任以及宣称因此而产生的任何损害负责。</div>
        <br/>
        <div>标普信评将其不同业务单位的活动保持分离，以保持相应活动的独立性和客观性。因此，标普信评的某些业务单位可能拥有其他业务单位所没有的信息。标普信评制定了政策和程序，以确保对各个分析过程中相关的特定非公开信息予以保密。</div>
        <br/>
        <div>标普信评可能从其评级和特定分析活动中获得报酬，报酬一般由证券发行人或承销人或者债务人支付。标普信评保留发布其意见和分析的权利。标普信评的公开评级和分析公布在其网站上 <a
          href="www.spgchinaratings.cn">www.spgchinaratings.cn</a> 并且可以通过其他方式发布，包括但不限于标普信评出版物和第三方转销商。
        </div>
        <br/>
        <div>非公开且保密：本非公开信用分析报告的有效性截至报告出具日期。标普信评自该日期起未对该非公开信用分析进行审阅，当前分析或存在差异。</div>
      </div>
    </div>
  </Watermark>
}

export default OldDetail