
import service from "@/utils/request"

const common = {
  // 获取邮箱验证码
  getRecordDetail(params) {
    return service.request({ url: "/pdf/getRecordDetail", method: "get", params })
  },
  getQualityDistribution(params) {
    return service.request({ url: "/pdf/getQualityDistribution", method: "get", params })
  },
  getQualityDistributionFI(params) {
    return service.request({ url: "/pdf/getQualityDistributionFI", method: "get", params })
  },
  getExtraIndex(params) {
    return service.request({ url: "/pdf/getExtraIndex", method: "get", params })
  },
  getDateList(params) {
    return service.request({ url: "/pdf/getDateListByCompanyId", method: "get", params })
  },

};

export default common;